<template>
    <el-dialog
        v-model="localDialogVisible"
        title="选择任务"
        width="75%"
        append-to-body
        :before-close="handleClose">
        <el-row>
            <el-col :span="1">
                <el-text>订单:</el-text>
            </el-col>
            <el-col :span="2">
                <el-text>{{ orderInfo.order_code }}</el-text>
            </el-col>
            <el-col :span="1">
                <el-text>产品:</el-text>
            </el-col>
            <el-col :span="2">
                <el-text>{{ orderInfo.product }}</el-text>
            </el-col>
            <el-col :span="1">
                <el-text>数量:</el-text>
            </el-col>
            <el-col :span="2">
                <el-text>{{ orderInfo.quantity }}</el-text>
            </el-col>
        </el-row>
        <el-divider />
        <el-table
            ref="tableJob" :data="localJobs" border
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick">
            <el-table-column
                align="center"
                width="30"
                label=""
                type="selection" />
            <el-table-column prop="name" label="工序" width="180" align="center" />
            <el-table-column label="产量" width="180" align="center">
                <template #default="{row}">
                    <el-input v-model="row.produce_quantity" type="number">
                        <template #append>{{ row.unit }}</template>
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column label="工时" width="180" align="center">
                <template #default="{row}">
                    <el-text>{{ workhour(row) }}</el-text>
                </template>
            </el-table-column>
            <el-table-column label="最早开工" width="180" align="center">
                <template #default="{row}">
                    <el-text>{{ formatDay(row.earlist_date) }}</el-text>
                </template>
            </el-table-column>
            <el-table-column label="最迟完成" width="180" align="center">
                <template #default="{row}">
                    <el-text>{{ formatDay(row.latest_finished) }}</el-text>
                </template>
            </el-table-column>
            <el-table-column label="工艺" width="240" align="center">
                <template #default="{row}">
                    <el-text>{{ formatCraft(row) }}</el-text>
                </template>
            </el-table-column>
            <el-table-column label="前序" width="100" align="center">
                <template #default="{row}">
                    <el-text>{{ row.last_process.join('|') }}</el-text>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-row>
                <el-col :span="4" :offset="6">
                    <el-button @click="closeDialog">取消</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="confirmDialog">生成任务</el-button>
                </el-col>
            </el-row>
        </template>
    </el-dialog>
</template>

<script>
import {fmtDD, notify} from '../assets/js/utils.js';
import {autoTabluar} from '../assets/js/schd.js';
import dayjs from 'dayjs';
import SchdStore from '../stores/schdtabular.js';
export default {
    props: {
        dialogVisible: Boolean,
        jobs: {
            type: Array,
            default: () => [],
        },
        orderInfo: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    emits: ['close'],
    setup() {
        const uWbStore = SchdStore();
        return {uWbStore};
    },
    data() {
        return {
            localDialogVisible: this.dialogVisible,
            multipleSelection: [],
        };
    },
    computed: {
        localJobs() {
            return [...this.jobs];
        },
    },
    watch: {
        dialogVisible(newVal) {
            this.localDialogVisible = newVal;
        },
    },
    methods: {
        handleClose(done) {
            this.$emit('close', done);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleRowClick(row) {
            this.$refs.tableJob.toggleRowSelection(row);
        },
        closeDialog() {
            this.handleClose(() => {
                this.localDialogVisible = false;
            });
        },
        async confirmDialog() {
            // 提交数据到joblist
            if (this.multipleSelection.length === 0) {
                notify('error', '请选择任务');
                return;
            }
            // 重复校验
            const jobs = this.multipleSelection.map((item, index) => {
                const jobtpl = autoTabluar.mkJobTpl();
                Object.assign(jobtpl, {
                    order_code: this.orderInfo.order_code,
                    product: this.orderInfo.product,
                    quantity: item.produce_quantity,
                    earliest: item.earlist_date,
                    latest: item.latest_finished,
                    shipment_date: this.orderInfo.shipment_date,
                    process_name: item.name,
                    job_code: `${this.orderInfo.order_code}-${index + 1}`,
                });
                autoTabluar.doJobFmtData(jobtpl);
                return jobtpl;
            });
            await this.uWbStore.addJobs(jobs);
            // this.uWbStore.addJobs(this.jobInfo);
            this.handleClose(() => {
                this.localDialogVisible = false;
            });
        },
        workhour(row) {
            return this.convertHoursToDaysAndHours(row.time);
        },
        formatDay(value) {
            return fmtDD(dayjs(value));
        },
        convertHoursToDaysAndHours(decimalHours) {
            const hours = Math.floor(decimalHours);
            const minutes = Math.round((decimalHours - hours) * 60);

            if (hours >= 24) {
                const days = Math.floor(hours / 24);
                const remainingHours = hours % 24;
                return `${days}天:${remainingHours}小时`;
            }
            if (hours === 0) {
                return `${minutes}分钟`;
            }
            return `${hours}小时:${minutes}分钟`;
        },
        formatCraft(row) {
            const crafts = [];
            switch (row.cate_code) {
            case 'print':
                if (row['色序'].length > 0) crafts.push(`色序:${row['色序']}`);
                if (row['光油'].length > 0) crafts.push(`光油:${row['光油']}`);
                break;
            case 'complex':
                if (row['类型'].length > 0) crafts.push(`类型:${row['类型']}`);
                if (row['胶水'].length > 0) crafts.push(`胶水:${row['胶水']}`);
                if (row['复次'].length > 0) crafts.push(`复次:${row['复次']}`);
                break;
            default:
                crafts.push('--');
            }
            return crafts.join(';');
        },
    },
};
</script>
<style scoped>
    .dialog-footer {
        background-color: red;
    }
</style>
