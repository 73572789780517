<template>
    <el-row class="query-row">
        <el-col :span="2">
            <el-input
                v-model="keywords.order_code"
                autocomplete="off"
                placeholder="订单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="3">
            <el-input
                v-model="keywords.product"
                autocomplete="off"
                placeholder="产品"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-input
                v-model="keywords.job_code"
                autocomplete="off"
                placeholder="任务单号"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.machine"
                autocomplete="off"
                clearable
                placeholder="选择机台">
                <el-option
                    v-for="(item) in machineList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value" />
            </el-select>
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isError"
                autocomplete="off"
                clearable
                placeholder="错误过滤">
                <el-option label="无错误" value="0" />
                <el-option label="有错误" value="1" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="2">
            <el-select
                v-model="keywords.isFinished"
                autocomplete="off"
                clearable
                placeholder="完成状态">
                <el-option label="已完成" value="是" />
                <el-option label="未完成" value="否" />
                <el-option label="全部" value="" />
            </el-select>
        </el-col>
        <el-col :span="3">
            <div class="date-range">
                <el-date-picker
                    v-model="keywords.start"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="最早开始"
                    end-placeholder=""
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    size="default" />
            </div>
        </el-col>
        <el-col :span="3">
            <div class="date-range">
                <el-date-picker
                    v-model="keywords.end"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="最迟完成"
                    end-placeholder=""
                    size="default"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD" />
            </div>
        </el-col>
    </el-row>
    <div class="batch-operate">
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="primary"
            @click="markedCalced(true)">
            标记参与计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="warning"
            @click="markedCalced(false)">
            标记不计算
        </el-button>
        <el-button
            v-if="jobList.some((item) => item.checked)"
            type="danger"
            @click="deleteBatchJobs">
            批量删除
        </el-button>
    </div>
    <el-row class="table-container">
        <el-auto-resizer>
            <template #default="{ height, width }">
                <el-table-v2
                    v-model:sort-state="sortState"
                    :columns="columns"
                    :data="jobList"
                    :width="width-10"
                    :height="height-10"
                    expand-column-key="index"
                    row-key="serial_number"
                    fixed
                    @column-sort="onSort">
                    <template #row="props">
                        <Row v-bind="props" />
                    </template>
                </el-table-v2>
            </template>
        </el-auto-resizer>
    </el-row>
    <JobListEdit ref="refJobEdit" />
    <LockAddOpt
        :dialog-visible="showAddLock"
        :plan-date="uWbStore.g_plan_date"
        :job-info="jobInfo" @close="showAddLock=false" />
</template>
<script setup lang="jsx">
import SchdStore from '../stores/schdtabular.js';
import JobListOp from './job-list-op.vue';
import {notify} from '../assets/js/utils.js';
// import {setPassive} from '../assets/js/setEventPassive.js';
import {h, computed, ref, Fragment, watch} from 'vue';
import {
    ElCheckbox, ElText, ElIcon, ElTag, ElMessageBox,
    TableV2SortOrder, ElButton, ElMessage,
} from 'element-plus';
import {WarningFilled} from '@element-plus/icons-vue';
import JobListEdit from './job-list-edit.vue';
import LockAddOpt from './lock-add-opt.vue';
import dayjs from 'dayjs';
const uWbStore = SchdStore();
const jobListBase = uWbStore.jobList;
const jobList = computed(() => {
    jobListBase.forEach((item) => {
        // 将错误信息压缩为一个字符串,然后在生成tag的时候进行解压
        if (item.error.length > 0) {
            item.children = [{'detail': item.error.map((err) => err.info).join('@@')}];
        } else {
            item.children = null;
        }
    });
    let _jobList = jobListBase;
    const {order_code, product, job_code, machine, isError, isFinished, start, end} = keywords.value;
    const _orderCode = order_code.trim();
    const _product = product.trim();
    const _jobCode = job_code.trim();
    if (_orderCode || _product || _jobCode || machine || isError || isFinished) {
        _jobList = jobListBase.filter((item) => {
            const matchesOrderCode = !_orderCode || item.order_code.includes(_orderCode);
            const matchesProductName = !_product || item?.product?.includes(_product);
            const matchesJobCode = !_jobCode || item?.job_code?.includes(_jobCode);
            const matchesMachine = !machine || item?.machine?.includes(machine);
            const filterError = isError === ''
                ? true
                : (isError === '0' ? item.error.length === 0 : item.error.length > 0);
            const filterFinished = isFinished === '' ? true : item.is_finished === isFinished;
            // 只有当所有检查都通过时，才返回 true
            return matchesOrderCode && matchesProductName && matchesJobCode
             && matchesMachine && filterError && filterFinished;
        });
    }
    if (start && start.length === 2) {
        const startDate = dayjs(start[0]);
        const endDate = dayjs(start[1]);
        _jobList = _jobList.filter((job) => {
            const jobDate = dayjs(job.earliest);
            return jobDate.diff(startDate, 'days') >= 0 && endDate.diff(jobDate, 'days') >= 0;
        });
    }
    if (end && end.length === 2) {
        const startDate = dayjs(end[0]);
        const endDate = dayjs(end[1]);
        _jobList = _jobList.filter((job) => {
            const jobDate = dayjs(job.latest);
            return jobDate.diff(startDate, 'days') >= 0 && endDate.diff(jobDate, 'days') >= 0;
        });
    }
    return _jobList;
});
watch(
    () => uWbStore.g_plan_date,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            jobListBase.forEach((job) => {
                uWbStore.updateJobCalcDateErrorTags(job);
            });
        }
    },
);
const SelectionCell = ({
    value,
    intermediate = false,
    onChange,
}) => {
    return h(ElCheckbox, {modelValue: value, indeterminate: intermediate, onChange});
};
const indexCell = ({isError, index}) => {
    const icon = isError
        ? () => h(ElIcon, null, {default: () => h(WarningFilled)})
        : null;
    return h(
        ElText,
        {
            class: 'mx-1',
            type: isError ? 'danger' : '',
        },
        {default: () => [index, icon ? icon() : null]},
    );
};
const errorTags = (errorStringList) => {
    const errors = errorStringList.split('@@');
    return h(Fragment, null, errors.map((error) => {
        return h(ElTag, {
            type: 'danger',
            size: 'large',
        }, {default: () => error});
    }));
};
const switchState = (rowData, val) => {
    rowData.isIncludedInCalculation = val;
};
const switchFinishedState = (rowData, val) => {
    setTimeout(() => {
        if (val === '是') {
            rowData.isIncludedInCalculation = false;
        }
        rowData.is_finished = val;
    }, 100);
};
const markedCalced = (val) => {
    for (const job of jobList.value) {
        if (job.checked && job.is_finished !== '是' && job.error.length === 0) {
            job.isIncludedInCalculation = val;
        }
    }
};
const Row = (obj) => {
    if (obj.rowData.detail) {
        return errorTags(obj.rowData.detail);
    }
    return obj.cells;
};
// 不继承父节点的style属性
Row.inheritAttrs = false;

// 操作列组件逻辑
const refJobEdit = ref(null);
const deleteJob = ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    ElMessageBox.confirm(
        `删除本行数据？${job.job_code}`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            uWbStore.deleteJob(job.serial_number);
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
const editJob = async ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    const res = await refJobEdit.value.show({
        title: '任务修改',
        data: Object.assign({}, job),
    });
    if (res) {
        // 更新数据
        uWbStore.updateJob(serial_number, res);
    }
};
const jobInfo = ref(null);
const showAddLock = ref(false);
const addLock = ({serial_number}) => {
    const job = uWbStore.getJob(serial_number);
    jobInfo.value = {...job};
    showAddLock.value = true;
};
const opCell = (row) => {
    return h(JobListOp,
        {
            key: `operate-${row.serial_number}`,
            row,
            onEdit(row) {
                editJob(row);
            },
            onDel(row) {
                deleteJob(row);
            },
            onLock(row) {
                addLock(row);
            },
        });
};
const deleteBatchJobs = () => {
    // 删除选中的所有任务信息
    const toDelJobs = jobList.value.filter((item) => item.checked);
    ElMessageBox.confirm(
        `删除${toDelJobs.length}行数据？`,
        '提醒',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        },
    )
        .then(() => {
            toDelJobs.forEach((item) => {
                uWbStore.deleteJob(item.serial_number);
            });
            notify('success', '删除成功', 1500);
        })
        .catch((msg) => {
            notify('info', msg === 'cancel' ? '取消' : msg, 1500);
        });
};
// 排序功能
const sortState = ref({
    'earliest': TableV2SortOrder.ASC,
    'latest': TableV2SortOrder.ASC,
});

const onSort = ({key, order}) => {
    sortState.value[key] = order;
    const _direction = sortState.value[key] === TableV2SortOrder.ASC ? 1 : -1;
    uWbStore.jobList.sort((a, b) => {
        if (a[key] !== b[key]) {
            return (a[key] > b[key] ? 1 : -1) * _direction;
        }
        return 0;
    });
};

// 查询功能
const keywords = ref({
    'order_code': '',
    'product': '',
    'machine': '',
    'job_code': '',
    'isError': '',
    'isFinished': '',
    'start': [],
    'end': [],
});
const machineList = computed(() => {
    const maclist = [...new Set(uWbStore.jobList.map((item) => {
        return item['machine'];
    }))];
    const _mac = [];
    maclist.forEach((m) => {
        if (m) {
            _mac.push({
                label: m,
                value: m,
            });
        }
    });
    _mac.sort((a, b) => a.label.localeCompare(b.label, 'zh-Hans', {numeric: true}));
    _mac.push({
        label: '全部',
        value: '',
    });
    return _mac;
});
const columns = [
    {
        key: 'selection',
        width: 40,
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            const onChange = (value) => (rowData.checked = value);
            return SelectionCell({value: rowData.checked, onChange});
        },
        headerCellRenderer: () => {
            const onChange = (value) => {
                jobList.value.forEach((item) => { item.checked = value; return item; });
            };
            const allSelected = jobList.value.every((row) => row.checked);
            const containsChecked = jobList.value.some((row) => row.checked);
            return SelectionCell({
                value: allSelected,
                intermediate: containsChecked && !allSelected,
                onChange,
            });
        },
    },
    {
        width: 75,
        title: '序号',
        key: 'index',
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData, rowIndex}) => {
            return indexCell({isError: rowData.error.length > 0, index: rowIndex + 1});
        },
    },
    {
        key: 'order_code',
        title: '订单号',
        dataKey: 'order_code',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_code',
        title: '任务号',
        dataKey: 'job_code',
        width: 120,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'product',
        title: '产品',
        dataKey: 'product',
        width: 220,
        class: 'table-col product col-left',
        headerClass: 'job-table-header',
    },
    {
        key: 'quantity',
        title: '数量',
        dataKey: 'quantity',
        width: 100,
        class: 'table-col col-right',
        headerClass: 'job-table-header',
    },
    {
        key: 'produced_quantity',
        title: '已生产',
        dataKey: 'produced_quantity',
        width: 100,
        class: 'table-col col-right',
        headerClass: 'job-table-header',
    },
    {
        key: 'process_name',
        title: '工序',
        dataKey: 'process_name',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
    },
    {
        key: 'is_finished',
        title: '完成',
        dataKey: 'is_finished',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            if (!rowData) return null;
            return <el-switch
                modelValue={rowData.is_finished}
                inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                active-value="否"
                active-text="否"
                inactive-text="是"
                inactive-value="是"
                onChange={(val) => {
                    switchFinishedState(rowData, val);
                }} />;
        },
    },
    {
        key: 'isIncludedInCalculation',
        title: '参与计算',
        dataKey: 'isIncludedInCalculation',
        width: 100,
        class: 'table-col col-center',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            if (!rowData) return null;
            return <el-switch
                modelValue={rowData.isIncludedInCalculation}
                inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                active-text="是"
                inactive-text="否"
                active-value={true}
                inactive-value={false}
                disabled={rowData.is_finished === '是' || rowData.error.length > 0}
                beforeChange={() => {
                    const _orgVal = rowData.isIncludedInCalculation;
                    if (_orgVal) return true;
                    if (!dayjs(uWbStore.g_plan_date).isValid()) return true;
                    if (dayjs(rowData.latest).diff(dayjs(uWbStore.g_plan_date), 'day') < 0) {
                        ElMessage({
                            showClose: true,
                            message: '最迟完成日期不能早于计划开始日期！请调整日期',
                            type: 'error',
                        });
                        return false;
                    }
                    return true;
                }}
                onChange={(val) => {
                    switchState(rowData, val);
                }} />;
        },
        // cellRenderer: ({rowData}) => {
        //     return <SwitchCalculation
        //         key={`isIncl-${rowData.serial_number}`}
        //         state={
        //             rowData.is_finished === '是' || rowData.error.length > 0
        //                 ? false
        //                 : rowData.isIncludedInCalculation
        //         }
        //         serialNumber={rowData.serial_number}
        //         disabled={rowData.is_finished === '是' || rowData.error.length > 0}
        //         onSwitch={switchState}
        //     />;
        // },
    },
    {
        key: 'earliest',
        title: '最早开始',
        dataKey: 'earliest',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
        sortable: true,
    },
    {
        key: 'latest',
        title: '最迟完成',
        dataKey: 'latest',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
        sortable: true,
    },
    {
        key: 'machine',
        title: '已排产机台',
        dataKey: 'machine',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_run',
        title: '开始时间',
        dataKey: 'job_run',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'job_end',
        title: '完成时间',
        dataKey: 'job_end',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
    },
    {
        key: 'remark',
        title: '备注',
        dataKey: 'remark',
        width: 100,
        class: 'table-col',
        headerClass: 'job-table-header',
        // cellRenderer: ({rowData}) => {
        //     return <>{rowData.serial_number}</>;
        // },
    },
    {
        key: 'op',
        width: 100,
        title: '操作',
        class: 'table-col',
        headerClass: 'job-table-header',
        cellRenderer: ({rowData}) => {
            return opCell(rowData);
        },
    },
];
</script>
<style scoped>
.table-col {
    border-left: 1px solid #ebeef5;
}
.batch-operate {
    position: absolute;
    top:20px;
    right:25px;
}
.product {
    font-size:0.8rem;
    text-align: left;
    text-wrap:nowrap;
}
.job-table-header {
    background-color:#f5f7fa !important;
    color:#606266 !important;
    border:1px solid #ebeef5;
    justify-content: center;
}
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 50px);
}
.date-range {
    width:100%;
}
:deep(.date-range .el-input__wrapper) {
    width: 85% !important;
}
</style>
