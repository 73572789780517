import {createRouter, createWebHashHistory} from 'vue-router';
import UserLogin from './components/login.vue';
import AdminPanel from './components/admin.vue';
import ApiTest from './components/api-test.vue';
import SchdPC from './components/schd-pc.vue';
import ProductList from './components/product-list.vue';
import MachineList from './components/machine-list.vue';
import CalendarList from './components/calendar-list.vue';
import SysMachineList from './components/sys-machine-list.vue';
import CorporationList from './components/corporation-list.vue';
import UserList from './components/user-list.vue';
import SpeedList from './components/speed-list.vue';
import ParamManage from './components/param-manage.vue';
import UserStore from './stores/user.js';

const user_routes = [
    // 动态字段以冒号开始
    {path: '/', name: 'home', component: UserLogin, label: '首页', role: '*'},
    {path: '/login', name: 'login', component: UserLogin, label: '登录', role: '*'},
    {path: '/api-test', name: 'api-test', component: ApiTest, label: '接口测试', menu: true, role: 'tester'},
    {path: '/schd-pc', name: 'schd-pc', component: SchdPC, label: '排产计算', menu: true, role: '*'},
    {
        path: '/products',
        name: 'products',
        component: ProductList,
        label: '产品管理',
        menu: true,
        role: '*',
    },
    {
        path: '/machine-list',
        name: 'machineList',
        component: MachineList,
        label: '机台管理',
        menu: true,
        role: '*',
    },
    {
        path: '/calendar-list',
        name: 'calendarList',
        component: CalendarList,
        label: '日历管理',
        menu: true,
        role: '*',
    },
    {
        path: '/speed-list',
        name: 'speedList',
        component: SpeedList,
        label: '机速管理',
        menu: true,
        role: '*',
    },
    {
        path: '/param-manage',
        name: 'paramManage',
        component: ParamManage,
        label: '参数管理',
        menu: true,
        role: '*',
    },
];

const admin_routes = [
    {path: '/admin', name: 'admin', component: AdminPanel, label: '系统管理', menu: true},
    {
        path: '/corporation-list',
        name: 'corporationList',
        component: CorporationList,
        label: '企业管理',
        menu: true,
    },
    {
        path: '/user-list',
        name: 'userList',
        component: UserList,
        label: '账号管理',
        menu: true,
    },
    {
        path: '/sys-machine-list',
        name: 'sysMachineList',
        component: SysMachineList,
        label: '机台管理',
        menu: true,
    },
];

const routes = [...user_routes, ...admin_routes];

function get_menu_routes() {
    const userstore = UserStore();
    return routes.filter(
        x => x.menu && (userstore.is_admin || x.role === '*' || userstore.has_role(x.role ?? x.name)),
    );
}

function get_admin_routes() {
    const userstore = UserStore();
    return admin_routes.filter(
        x => x.menu && (userstore.is_admin || userstore.has_role(x.role ?? x.name)),
    );
}

function get_user_routes() {
    const userstore = UserStore();
    return user_routes.filter(
        x => x.menu && (userstore.is_admin || x.role === '*' || userstore.has_role(x.role ?? x.name)),
    );
}

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
});

router.beforeEach(async (to, from) => {
    const userstore = UserStore();
    if (!userstore.user) await userstore.load();
    if (to.name === 'home' || to.name === 'login') {
        if (userstore.user) return {name: get_menu_routes()[0].name};
    } else {
        if (!userstore.user) return {name: 'login'};
    }
});

export default router;
export {get_menu_routes, get_admin_routes, get_user_routes, routes};
