<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        class="dialog"
        :title="title"
        align-center>
        <div class="machine_table_div">
            <el-table
                ref="elTable"
                class="pro_table"
                :data="mac_list"
                :highlight-current-row="true"
                :border="true"
                :row-class-name="tableRowClassName">
                <el-table-column fixed type="index" align="center" />
                <el-table-column prop="code" label="机台编码" width="120" align="center" />
                <el-table-column prop="name" label="机台名称" width="120" align="center" />
                <el-table-column prop="speed" label="最大速度" width="120" align="center" />
                <el-table-column prop="erp_code" label="同步编码" width="120" align="center" />
                <el-table-column prop="erp_name" label="同步名称" width="120" align="center" />
                <el-table-column prop="erp_speed" label="同步最大速度" width="120" align="center" />
                <el-table-column fixed="right" label="操作" align="center">
                    <template #default="data">
                        <el-tooltip
                            v-if="data.row.opt=='update'"
                            content="更新机台" placement="top">
                            <el-button
                                link
                                type="primary"
                                size="large"
                                @click="edit_machine($event, data.row)">
                                <el-icon><refresh /></el-icon>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip
                            v-if="data.row.opt=='delete'"
                            content="删除机台" placement="top">
                            <el-button
                                link
                                type="primary"
                                size="large"
                                @click="del_machine($event, data.row)">
                                <el-icon><delete /></el-icon>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip
                            v-if="data.row.opt=='insert'"
                            content="添加机台" placement="top">
                            <el-button
                                link
                                type="primary"
                                size="large"
                                @click="add_machine($event, data.row)">
                                <el-icon><plus /></el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="foot_div">
            <el-row>
                <el-col :span="10" />
                <el-col :span="4">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="10" />
            </el-row>
        </div>
    </el-dialog>
    <sysMachineOpt ref="sysMachineOpt" />
</template>

<script>
import {Refresh, Delete, Plus} from '@element-plus/icons-vue';
import {notify} from '../assets/js/utils.js';
import sysMachineOpt from './sys-machine-opt.vue';
export default {
    name: 'MachineSynchronization',
    components: {
        Refresh,
        Delete,
        Plus,
        sysMachineOpt,
    },
    props: {
        initData: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            mac_list: [], // 组合的机台列表
            corp: null,
            visibled: false,
            resolve: null,
            reject: null,
            title: '机台信息同步',
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    watch: {
    },
    methods: {
        async show(options) {
            this.corp = options.corp;
            this.visibled = true;
            await this.formatMacList();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async get_sch_machines() {
            const url = `/api/corporations/${this.corp.id}/machines/`;
            const res = await this.axios.get(url);
            return res.data.data;
        },
        async get_erp_machines() {
            // const data = {
            //     'kwargs': {
            //         'plan_date': '2024-07-19',
            //         'data': [
            //             {
            //                 'task_id': 12905,
            //                 'lot': '202303734+2',
            //                 'process_id': '2207189501:print-1',
            //                 'process_name': '印刷',
            //                 'machine_name': '汇通1#机',
            //                 'quantity': 1200,
            //                 'produce_time': '2024-07-20 16:00:00',
            //                 'remark': '自动排产',
            //             },
            //         ],
            //     },
            // };
            // const url = `/api/colorprint/schedules`;
            // const res = await this.axios.post(url, JSON.stringify(data));
            // console.log(res);
            const url = `/api/colorprint/${this.corp.id}/machine_list`;
            const res = await this.axios.get(url);
            return res.data.result;
        },
        async formatMacList() {
            const sch_mac_list = await this.get_sch_machines();
            const erp_mac_list = await this.get_erp_machines();
            this.mac_list = [];
            for (let i = sch_mac_list.length - 1; i >= 0; i--) {
                const sch_mac = sch_mac_list[i];
                for (let j = erp_mac_list.length - 1; j >= 0; j--) {
                    const erp_mac = erp_mac_list[j];
                    if (sch_mac.id === erp_mac.id) {
                        if (sch_mac.name === erp_mac.name && sch_mac.code === erp_mac.code
                        && sch_mac.speed === erp_mac.speed) {
                            this.mac_list.push({
                                ...sch_mac,
                                erp_name: erp_mac.name,
                                erp_code: erp_mac.code,
                                erp_speed: erp_mac.speed,
                                cate_code: erp_mac.cate_code,
                                color: 'green',
                                opt: null,
                            });
                        } else {
                            this.mac_list.push({
                                ...sch_mac,
                                erp_name: erp_mac.name,
                                erp_code: erp_mac.code,
                                erp_speed: erp_mac.speed,
                                cate_code: erp_mac.cate_code,
                                color: 'yellow',
                                opt: 'update',
                            });
                        }
                        erp_mac_list.splice(j, 1);
                        sch_mac_list.splice(i, 1);
                        break;
                    }
                }
            }
            for (let i = sch_mac_list.length - 1; i >= 0; i--) {
                const sch_mac = sch_mac_list[i];
                for (let j = erp_mac_list.length - 1; j >= 0; j--) {
                    const erp_mac = erp_mac_list[j];
                    if (sch_mac.code === erp_mac.code) {
                        if (sch_mac.name === erp_mac.name && sch_mac.speed === erp_mac.speed) {
                            this.mac_list.push({
                                ...sch_mac,
                                erp_name: erp_mac.name,
                                erp_code: erp_mac.code,
                                erp_speed: erp_mac.speed,
                                cate_code: erp_mac.cate_code,
                                color: 'darkseagreen',
                                opt: null,
                            });
                        } else {
                            this.mac_list.push({
                                ...sch_mac,
                                erp_name: erp_mac.name,
                                erp_code: erp_mac.code,
                                erp_speed: erp_mac.speed,
                                cate_code: erp_mac.cate_code,
                                color: 'darkorange',
                                opt: 'update',
                            });
                        }
                        erp_mac_list.splice(j, 1);
                        sch_mac_list.splice(i, 1);
                        break;
                    }
                }
            }
            for (let i = sch_mac_list.length - 1; i >= 0; i--) {
                const sch_mac = sch_mac_list[i];
                this.mac_list.push({
                    ...sch_mac,
                    erp_name: '不存在',
                    erp_code: '不存在',
                    erp_speed: '',
                    color: 'darkgray',
                    opt: 'delete',
                });
                sch_mac_list.splice(i, 1);
            }
            for (let j = erp_mac_list.length - 1; j >= 0; j--) {
                const erp_mac = erp_mac_list[j];
                this.mac_list.push({
                    code: '不存在',
                    name: '不存在',
                    speed: '',
                    erp_name: erp_mac.name,
                    erp_code: erp_mac.code,
                    erp_speed: erp_mac.speed,
                    cate_code: erp_mac.cate_code,
                    color: 'aquamarine',
                    opt: 'insert',
                });
                erp_mac_list.splice(j, 1);
            }
        },
        async add_machine(_, row) {
            const cate_code = row.cate_code.replace('bagging', 'mkbag');
            const cate_data = await this.getCategory(cate_code);
            const data = await this.$refs.sysMachineOpt.show({
                title: '机台添加',
                data: {
                    corp_id: this.corp.id,
                    corp_name: this.corp.name,
                    name: row.erp_name,
                    code: row.erp_code,
                    speed: row.erp_speed,
                    cate_id: cate_data[0].id,
                    data: {},
                },
                machine_list: await this.get_sch_machines(),
            });
            if (data) {
                this.formatMacList();
                this.initData();
            }
        },
        async update_machine(_, row) {
            const url = `/api/corporations/${this.corp}/machines/${row.id}`;
            const data = {
                'id': row.id,
                'cate_id': row.cate_id,
                'code': row.erp_code,
                'name': row.erp_name,
                'speed': row.erp_speed,
                'data': row.params,
            };
            const res = await this.axios.post(url, JSON.stringify(data));
            if (!(res.data.result || false)) {
                notify('error', res.data.error, 0);
                return;
            }
            notify('success', '添加成功', 0);
            this.formatMacList();
            this.initData();
        },
        async del_machine(_, row) {
            this.$confirm('是否确认删除该机台', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                const url = `/api/corporations/${this.corp}/machines//${row.id}`;
                const res = await this.axios.delete(url);
                if (res.data.result) {
                    notify('success', '删除成功', 0);
                } else {
                    notify('error', res.data.error, 0);
                    return;
                }
                this.formatMacList();
                this.initData();
            }).catch(() => {
            });
        },
        async getCategory(code) {
            console.log(code);
            const url = `/api/categories/?q=${code}`;
            const res = await this.axios.get(url);
            return res.data.data;
        },
        tableRowClassName({row, rowIndex}) {
            return 'color_' + row['color'];
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.dialog{
    position: relative !important;
    width: 70% !important;
    height: 80vh;
    max-height: 1200px;
    max-width: 1600px;
    min-height: 600px;
    min-width: 800px;
}
.machine_table_div {
    height: calc(100% - 110px) !important;
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    left: 0px;
    padding: 2px 16px 2px 16px
}
.pro_table {
    height:100% !important;
    width: calc(100% - 32px) !important;
}
.btn_button{
    height: 50px !important;
    max-width: 300px !important;
    min-width: 200px !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    text-align: justify !important;
    text-align-last: justify !important;
}
.foot_div{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    left: 0px;
}
.color_darkseagreen{
    background-color: darkseagreen !important;
}
.color_darkorange{
    background-color:darkorange !important;
}
.color_darkgray{
    background-color: darkgray !important;
}
.color_aquamarine{
    background-color: aquamarine !important;
}
</style>
