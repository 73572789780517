import axios from 'axios';
import {OrdersModel, JobsModel, SchdModel, colsToObj} from './schd.js';
import dayjs from 'dayjs';
/**
 * @import {JobResult,ScheduleResult,ProductInfo} from './schdTypes.js';
 */
/**
 * eOrder,Erp的订单信息
 * @typedef {Object} eOrder
 * @property {string} id - task_id
 * @property {string} lot - task_lot,order_code
 * @property {string} delivery_date - 发货日期  YYYY-MM-DD
 * @property {string} plan_date - 计划日期  YYYY-MM-DD
 * @property {string} pro_name - 产品名称
 * @property {string} pro_code - 产品编号
 * @property {Number} quantity
 * @property {string} unit - 单位
 * @property {string} ver_name - 版本
 */
/**
 * eJob ERP task信息,对应的是schd的job信息
 * @typedef {Object} eJob
 * @property {string} task_id
 * @property {string} delivery_date - shipment_date
 * @property {string} earlist_date
 * @property {string} lasted_date
 * @property {string} lot - job
 * @property {Number} outPut_quantity - produce_quantity
 * @property {Number} plan_quantity - quantity
 * @property {string} process_id
 * @property {string} process_name
 * @property {string} job_code
 * @property {string} v_id
 * @property {string} cate 工序类别
 */

/**
 * eSch,ERP排产信息
 * @typedef {Object} eSch
 * @property {string} task_id
 * @property {string} process_id
 * @property {string} lot
 * @property {string} job_code
 * @property {string} machine_name
 * @property {string} machine_id
 * @property {string} outPut_quantity
 * @property {string} plan_quantity
 * @property {string} process_name
 * @property {string} produce_date
 * @property {string} produce_time
 * @property {string} quantity
 */

/**
 * eSchedule,自动排产发送ERP的排产信息
 * @typedef {Object} eSchedule
 * @property {string} task_id
 * @property {string} process_name
 * @property {string} process_id
 * @property {string} machine_name
 * @property {string} lot
 * @property {string} quantity
 * @property {string} produce_date
 * @property {string} produce_time
 * @property {string} process_cate_name
 * @property {string} remark
 */

/**
 * @typedef {Object} eTask
 * @property {Array<eOrder>} order
 * @property {Array<eJob>} process
 * @property {Array<eSch>} sch
 */

/**
 *
 * @param {Map<k,eOrder>} mOrder
 */
const eOrder2Schd = (mOrder) => {
    // 从ERP的order数据到自动排产的order-list数据
    const oModel = OrdersModel.dataDefined.columns;
    const orderList = [];

    mOrder.forEach((o) => {
        const _obj = colsToObj(oModel);
        // 处理信息
        _obj.order_code = o.lot;
        _obj.product = `${o.pro_name}:${o.ver_name}`;
        _obj.pro_code = `${o.pro_code}:${o.ver_name}`;
        _obj.quantity = o.quantity;
        _obj.shipment_date = o.delivery_date;
        _obj.is_finished = '否';
        _obj.earliest = o.plan_date;
        _obj.latest = o.delivery_date;
        _obj.task_id = o.id;
        orderList.push(_obj);
    });

    return orderList;
};

/**
 *
 * @param {Map<k,Array<eJob>>} mJob
 * @param {Map<k,eOrder>} mOrder
 */
const eJob2Schd = (mJob, mOrder) => {
    const jobList = [];
    const oModel = JobsModel.dataDefined.columns;
    mJob.forEach(jobs => {
        jobs.forEach((job, _index) => {
            const _obj = colsToObj(oModel);
            _obj.order_code = job.lot;
            _obj.job_code = job.job_code;
            _obj.product = `${mOrder.get(job.task_id).pro_name}:${mOrder.get(job.task_id).ver_name}`;
            _obj.quantity = job.plan_quantity;
            _obj.produced_quantity = job.outPut_quantity;
            _obj.is_finished = '否';
            _obj.shipment_date = job.delivery_date;
            _obj.earliest = job.earlist_date;
            _obj.latest = job.lasted_date;
            _obj.task_id = job.task_id;
            _obj.process_name = job.process_name;

            jobList.push(_obj);
        });
    });
    return jobList;
};

/**
 *
 * @param {Array<eSch>} eSchs
 * @param {Map<k,eOrder>} mOrder
 */
const eSch2Schd = (eSchs, mOrder) => {
    const oModel = SchdModel.dataDefined.columns;
    return eSchs.map((sch) => {
        const _obj = colsToObj(oModel);
        _obj.order_code = sch.lot;
        _obj.job_code = sch.job_code;
        _obj.product = `${mOrder.get(sch.task_id).pro_name}:${mOrder.get(sch.task_id).ver_name}`;
        _obj.quantity = sch.plan_quantity;
        _obj.produced_quantity = sch.outPut_quantity;
        _obj.machine = sch.machine_name;
        _obj.task_id = sch.task_id;
        _obj.date = sch.produce_date;
        _obj.process_name = sch.process_name;
        return _obj;
    });
};
const getErpPrd = async (p_code, v_name) => {
    const url = `/api/colorprint/product`;
    const params = {p_code, v_name};
    const prdinfo = await axios.get(url, {params});
    return prdinfo.data.result;
};

/**
 *
 * @param {string} product
 * @returns {Promise<ProductInfo[]>}
 */
const getPrdInfo = async (product) => {
    const url = `/api/products/`;
    const params = {};
    if (product) {
        params.q = product;
    }
    const res = await axios.get(url, {params});
    const product_data = res.data.data;
    const urlp = `/api/parameter-defineds/?t=1`;
    const resP = await axios.get(urlp);
    const param_defined = resP.data.data.reduce((res, data) => {
        if (res[data['category']['code']]) {
            res[data['category']['code']].push(data['name']);
        } else {
            res[data['category']['code']] = [data['name']];
        }
        return res;
    }, {});
    for (let i = 0; i < product_data.length; i++) {
        if (product_data[i]['data']['工艺'].length > 0) {
            product_data[i]['state_name'] = '已配置';
        } else {
            product_data[i]['state_name'] = '未配置';
        }
        product_data[i]['data']['工艺'] = product_data[i]['data']['工艺'].reduce(
            (res, data) => {
                const params = {};
                for (const key in data) {
                    if (param_defined[data['cate_code']]?.indexOf(key) >= 0) {
                        params[key] = data[key];
                        delete data.key;
                    }
                }
                let param_str = '';
                for (const key in params) {
                    param_str += `${key}:${params[key]};`;
                }
                data['param_hide'] = params;
                data['params'] = param_str;
                res.push(data);
                return res;
            }, []);
    }
    if (product_data.length > 0) {
        return product_data;
    }
    return [];
};

const getErpTask = async () => {
    try {
        const taskInfo = await axios.get('/api/colorprint/tasks');
        if (taskInfo.data.error) {
            throw new Error(taskInfo.data.error);
        }
        if (taskInfo.data.result.status !== 1) {
            throw new Error(taskInfo.data.result.message);
        }
        // 开始处理任务信息
        // @TODO 目前只支持印刷相关的任务
        const validCate = ['print'];
        // 先过滤process,根据process过滤order,然后过滤相关sch
        /** @type {eTask} */
        const {order, process, sch} = taskInfo.data.result.data;
        /** @type {Map<k,Array<eJob>>} */
        const processMap = new Map();
        for (const item of process) {
            if (validCate.includes(item.cate) && !processMap.has(item.task_id)) {
                const _p = process.filter((p) => {
                    return validCate.includes(p.cate) && p.task_id === item.task_id;
                });
                _p.forEach((p, index) => {
                    p.job_code = `${p.lot}-${index + 1}`;
                });
                processMap.set(
                    item.task_id,
                    _p,
                );
            }
        }
        const orderMap = new Map();
        for (const item of order) {
            if (processMap.has(item.id)) {
                orderMap.set(item.id, item);
            }
        }
        const schArray = [];
        for (const item of sch) {
            if (processMap.has(item.task_id) && validCate.includes(item.cate)) {
                const p = processMap.get(item.task_id).find((p) => p.process_id === item.process_id);
                if (p !== undefined) {
                    item.job_code = p.job_code;
                    schArray.push(item);
                }
            }
        }
        return {
            'eOrders': eOrder2Schd(orderMap),
            'eJobs': eJob2Schd(processMap, orderMap),
            'eSchs': eSch2Schd(schArray, orderMap),
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // 处理 Axios 错误
            console.error('Axios Error:', error.message);
        }
        throw error;
    }
};

/**
 * 将自动排产排产数据发送到ERP系统中
 * @param {Object} autoData
 * @param {JobResult[]} autoData.jobList
 * @param {ScheduleResult[]} autoData.schdList
 * @param {ScheduleResult[]} [autoData.estimateSchdList]
 */
const sendSchToERP = async (autoData) => {
    try {
        const schdList = changeSchToErp(autoData);
        if (autoData.estimateSchdList.length > 0) {
            schdList.push(...changeEsSchToErp(autoData));
        }
        const url = '/api/colorprint/schedules';
        const process_category = processCateSet(schdList);
        const response = await axios.post(url, JSON.stringify({
            kwargs: {
                'data': schdList,
                plan_date: autoData.plan_date,
                process_category,
            },
        }));
        if (response.data.error) {
            throw new Error(response.data.error);
        }
        if (response.data.result.status !== 1) {
            throw new Error(response.data.result.message);
        }
        return response.data.result;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            // 处理 Axios 错误
            console.error('Axios Error:', error.message);
        }
        throw error;
    }
};
/**
 *
 * @returns {Array<eSchedule>}
 */
/**
 * 将自动排产排产数据转换为ERP所需要的排产数据
 * @param {Object} param
 * @param {JobResult[]} param.jobList
 * @param {ScheduleResult[]} param.schdList
 * @returns {Array<eSchedule>}
 */
const changeSchToErp = ({jobList, schdList}) => {
    const jobMap = new Map();
    jobList.forEach((item) => {
        jobMap.set(item.job_code, item);
    });
    /** @type {Array<eSchedule>} */
    const eSchdList = [];
    schdList.forEach((sch) => {
        if (jobMap.has(sch.job_code)) {
            /** @type {eSchedule} */
            const eSch = {};
            const job = jobMap.get(sch.job_code);
            eSch.lot = job.order_code;
            eSch.task_id = job?.task_id || '';
            eSch.remark = sch.remark || '自动排产';
            eSch.process_id = job.process_id;
            eSch.process_name = sch.process_name;
            eSch.quantity = sch.quantity;
            eSch.process_cate_name = sch.process_cate_name;
            eSch.machine_name = sch?.machine_info?.name || sch.machine;
            const _date = dayjs(sch.date, 'YYYY-MM-DD');
            const _pre = dayjs(`${sch.date} ${sch.pre}`, 'YYYY-MM-DD HH:mm:ss');
            eSch.produce_date = _date.format('YYYY-MM-DD');
            eSch.produce_time = _pre.isValid()
                ? _pre.format('YYYY-MM-DD HH:mm:ss')
                : _date.format('YYYY-MM-DD HH:mm:ss');
            eSchdList.push(eSch);
        }
    });
    return eSchdList;
};
/**
 * 将预估排产的数据转换为满足Erp要求的排产数据
 * @param {Object} param
 * @param {ScheduleResult[]} param.estimateSchdList
 * @returns {Array<eSchedule>}
 */
const changeEsSchToErp = ({estimateSchdList}) => {
    /** @type {Array<eSchedule>} */
    const eSchdList = [];
    estimateSchdList.forEach((sch) => {
        /** @type {eSchedule} */
        const eSch = {};
        eSch.lot = sch.order_code;
        eSch.task_id = sch?.task_id || '';
        eSch.remark = sch.remark || '自动排产';
        eSch.process_name = sch.process_name;
        eSch.quantity = sch.quantity;
        eSch.process_cate_name = sch.process_cate_name;
        eSch.machine_name = sch?.machine_info?.name || sch.machine;
        eSch.produce_date = sch.date;
        eSch.produce_time = sch.pre;
        eSchdList.push(eSch);
    });
    return eSchdList;
};
/**
 *
 * @param {Array<eSchedule>} schedules
 * @returns {Array<String>}
 */
const processCateSet = (schedules) => {
    return schedules.reduce((acc, cur) => {
        if (acc.includes(cur.process_cate_name)) {
            acc.push(cur.process_cate_name);
        }
        return acc;
    }, []);
};
export default {eOrder2Schd, getErpPrd, eJob2Schd, getErpTask, sendSchToERP, getPrdInfo};
export {eOrder2Schd, getErpPrd, eJob2Schd, getErpTask, sendSchToERP, getPrdInfo};
