<template>
    <div class="main_div">
        <el-form
            :model="args"
            class="search_form">
            <el-row>
                <el-col :span="15" />
                <el-col :span="6">
                    <el-form-item label="" prop="product">
                        <el-input
                            v-model="args.product"
                            autocomplete="off"
                            placeholder="名称或编码"
                            clearable
                            maxlength="32"
                            @change="init_data" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="product_list"
            :data="product_data.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @expand-change="on_expand_change">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <tabProcess :data="props.row.data['工艺']" class="sub-table" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column prop="client" label="客户编码" width="180" align="center" />
            <el-table-column prop="code" label="产品编码" width="180" align="center" />
            <el-table-column prop="name" label="产品名称" width="180" align="center" />
            <el-table-column prop="stru" label="结构" width="180" align="center" />
            <el-table-column prop="spec" label="规格" width="180" align="center" />
            <el-table-column prop="state_name" label="状态" width="180" align="center" />
            <el-table-column prop="desc" label="备注" align="center" />
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_product()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="data">
                    <el-tooltip content="编辑产品" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="edit_product($event, data.row)">
                            <el-icon><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="删除产品" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="del_product($event, data.row)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="product_data.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        <productOpt ref="productOpt" />
    </div>
</template>
<script>
import {notify} from '../assets/js/utils.js';
import tabProcess from './tab-process.vue';
import productOpt from './product-opt.vue';
import axios from 'axios';
import {Plus, Search, Delete, Edit} from '@element-plus/icons-vue';
export default {
    name: 'ProductList',
    components: {
        tabProcess,
        productOpt,
        Plus,
        Search,
        Delete,
        Edit,
    },
    data: function() {
        return {
            args: {
                product: '',
            },
            expand_row_keys: [],
            product_data: [],
            param_defined: [],
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 20, // 每页的数据条数
        };
    },
    mounted() {
        this.init_data();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (index < 0) {
                this.expand_row_keys.push(key);
            } else {
                this.expand_row_keys.splice(index, 1);
            }
        },
        async add_product() {
            const data = await this.$refs.productOpt.show({
                title: '产品添加',
                data: null,
                product_list: this.product_data,
            });
            if (data) {
                this.init_data();
            }
        },
        async del_product(_, row) {
            this.$confirm('是否确认删除该产品', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                const url = `/api/products/${row.id}`;
                const res = await this.axios.delete(url);
                if (res.data.result) {
                    notify('success', '删除成功', 0);
                } else {
                    notify('error', res.data.error, 0);
                }
                this.init_data();
            }).catch(() => {
            });
        },
        async edit_product(_, row) {
            const data = await this.$refs.productOpt.show({
                title: '产品修改',
                data: JSON.parse(JSON.stringify(row)),
                product_list: this.product_data,
            });
            if (data) {
                this.init_data();
            }
        },
        async init_data() {
            await this.init_param();
            const url = `/api/products/`;
            const params = {};
            if (this.args.product) {
                params.q = this.args.product;
            }
            const res = await axios.get(url, {params});
            this.product_data = res.data.data;
            for (let i = 0; i < this.product_data.length; i++) {
                if (this.product_data[i]['data']['工艺'].length > 0) {
                    this.product_data[i]['state_name'] = '已配置';
                } else {
                    this.product_data[i]['state_name'] = '未配置';
                }
                this.product_data[i]['data']['工艺'] = this.product_data[i]['data']['工艺'].reduce(
                    (res, data) => {
                        const params = {};
                        for (const key in data) {
                            if (this.param_defined[data['cate_code']]?.indexOf(key) >= 0) {
                                params[key] = data[key];
                                delete data.key;
                            }
                        }
                        let param_str = '';
                        for (const key in params) {
                            param_str += `${key}:${params[key]};`;
                        }
                        data['param_hide'] = params;
                        data['params'] = param_str;
                        res.push(data);
                        return res;
                    }, []);
            }
        },
        async init_param() {
            const url = `/api/parameter-defineds/?t=1`;
            const res = await this.axios.get(url);
            this.param_defined = res.data.data.reduce((res, data) => {
                if (res[data['category']['code']]) {
                    res[data['category']['code']].push(data['name']);
                } else {
                    res[data['category']['code']] = [data['name']];
                }
                return res;
            }, {});
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.product_list{
    position: absolute;
    top:50px;
    bottom: 60px;
    width: 100%;
    height: calc(100% - 110px);
}
.search_form {
    height:50px;
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
