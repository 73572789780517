<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        align-center
        class="calendar-editor"
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="can_data"
            class="calendar_form data_form"
            :rules="cal_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" class="label_style" prop="name">
                        <el-input
                            ref="codeInput"
                            v-model="can_data.name"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开始日期" class="label_style" prop="start">
                        <el-date-picker v-model="can_data.start" type="date" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="规则模式" class="label_style" prop="repeat">
                        <el-select
                            ref="categorySelect"
                            v-model="can_data.repeat"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option label="循环" value="1" />
                            <el-option label="临时" value="0" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="规则周期" class="label_style" prop="cycle">
                        <el-input
                            v-model="can_data.cycle"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            disabled
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="day_div">
            <fieldset class="info-box days">
                <legend>
                    工作时间
                    <el-popover
                        placement="top-start"
                        effect="dark"
                        :width="180"
                        trigger="hover"
                        content="设置每个循环周期内的工作时间，设置几天即代表一个周期多少天,最少要设置一天的">
                        <template #reference>
                            <el-icon>
                                <QuestionFilled />
                            </el-icon>
                        </template>
                    </el-popover>
                </legend>
                <el-table
                    ref="elTable"
                    class="day_table"
                    :data="days"
                    :border="true">
                    <el-table-column prop="index" align="center" label="" width="100">
                        <template #default="data">
                            <p>第{{ data.$index + 1 }}天 </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="起始时间" prop="start_time" header-align="center" align="center">
                        <template #default="data">
                            <el-time-select
                                v-model="data.row.start_time"
                                start="00:00"
                                end="24:00"
                                :disabled-seconds="true" />
                        </template>
                    </el-table-column>
                    <el-table-column label="截至时间" prop="end_time" header-align="center" align="center">
                        <template #default="data">
                            <el-time-select
                                v-model="data.row.end_time"
                                start="00:00"
                                end="24:00"
                                :disabled-seconds="true" />
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="" width="100" align="center">
                        <template #header>
                            <el-button
                                class="btn-add"
                                @click="add_days()">
                                <el-icon><plus /></el-icon>
                            </el-button>
                        </template>
                        <template #default="data">
                            <el-tooltip content="删除" placement="top">
                                <el-button
                                    link
                                    type="primary"
                                    size="large"
                                    @click="del_days($event, data.$index)">
                                    <el-icon><delete /></el-icon>
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </fieldset>
        </div>
        <div class="mac_div">
            <fieldset
                class="info-box machines">
                <legend>
                    机台列表
                    <el-popover
                        placement="top-start"
                        effect="dark"
                        :width="180"
                        trigger="hover"
                        content="使用本日历生产的机台">
                        <template #reference>
                            <el-icon>
                                <QuestionFilled />
                            </el-icon>
                        </template>
                    </el-popover>
                </legend>
                <el-checkbox-group
                    v-model="checkeds"
                    @change="handleCheckedColumnChangeSales">
                    <el-checkbox
                        v-for="(item) in machine_list"
                        :key="item.id"
                        :label="item.name" />
                </el-checkbox-group>
            </fieldset>
        </div>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import {notify} from '../assets/js/utils.js';
import {Plus, Delete, QuestionFilled} from '@element-plus/icons-vue';
export default {
    name: 'CalendarOpt',
    components: {
        Delete,
        Plus,
        QuestionFilled,
    },
    props: {
    },
    data() {
        return {
            can_data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '机台修改',
            machine_list: [],
            calendar_list: [],
            checkeds: [],
            checked_flag: true,
            last_checkeds: [],
            mac_name_data: {},
            days: [],
            height: window.innerHeight,
            cal_rules: {
                name: [{required: true, message: '请输入日历名称', trigger: ['change', 'blur']}],
                start: [{required: true, message: '请输入开始日期', trigger: ['change', 'blur']}],
                repeat: [{required: true, message: '请选择规则模式', trigger: ['change', 'blur']}],
            },
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
        this.initMachine();
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.calendar_list = options.calendar_list;
            if (options.data) {
                this.can_data = options.data;
                this.can_data.repeat = this.can_data.repeat.toString();
                this.can_data.start = new Date(this.can_data.start);
                this.checkeds = this.can_data.machine_names;
                this.last_checkeds = this.can_data.machine_names;
                this.days = this.can_data.days;
                this.can_data.cycle = this.days.length;
            } else {
                this.can_data = {};
                this.days = [];
                this.checkeds = [];
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            try {
                if (!(await this.$refs.proform?.validate())) {
                    return;
                }
                if (!(await this.checked())) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            const data = {
                name: this.can_data.name,
                // start: this.can_data.start.toISOString().slice(0, 10),
                start: this.date_format(this.can_data.start),
                repeat: this.can_data.repeat,
                state: 1,
                days: this.days.reduce((res, data) => {
                    if (data['start_time']) {
                        if (data['end_time'] === '00:00') {
                            data['end_time'] = '23:59';
                        }
                        console.log(typeof data['start_time'], typeof data['end_time']);
                        res.push([data['start_time'], data['end_time']]);
                    } else {
                        res.push([]);
                    }
                    return res;
                }, []),
            };
            if (this.can_data.id) {
                for (let i = 0; i < this.can_data.id.length; i++) {
                    const url = `/api/calendar/${this.can_data.id[i]}`;
                    await this.axios.delete(url);
                }
            }
            let success = true;
            let message = '提交失败';
            for (let i = 0; i < this.checkeds.length; i++) {
                const url = `/api/calendar/`;
                data['mach_id'] = this.mac_name_data[this.checkeds[i]].id;
                const res = await this.axios.post(url, JSON.stringify(data));
                if (res.data.error) {
                    success = false;
                    message = res.data.error;
                    break;
                }
            }
            if (!success) {
                notify('error', message, 0);
                return;
            }
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        async checked() {
            let flag = true;
            if (this.can_data.name) {
                const start = this.date_format(this.can_data['start']);
                const key1 = `${this.can_data['name']}/${start}/${this.can_data['repeat']}`;
                for (let i = 0; i < this.calendar_list.length; i++) {
                    const can_data = this.calendar_list[i];
                    const key2 = `${can_data['name']}/${can_data['start']}/${can_data['repeat']}`;
                    if ((this.can_data.id || []).toString() !== this.calendar_list[i]['id'].toString()
                    && key1 === key2) {
                        notify('error', '已存在名称、开始日期和规则模式一致的日历规则！', 0);
                        flag = false;
                        break;
                    }
                }
                if (!flag) {
                    return flag;
                }
            }
            if (this.checkeds.length > 0) {
                for (let i = 0; i < this.checkeds.length; i++) {
                    const res = await this.checkMchineCalendar(this.checkeds[i]);
                    if (!res) {
                        flag = false;
                        break;
                    }
                }
                if (!flag) {
                    return flag;
                }
            } else {
                notify('error', '请选择使用该日历规则的机台！', 0);
                flag = false;
                return flag;
            }
            if (this.days.length === 0) {
                notify('error', '请设置该日历的生产时间！', 0);
                flag = false;
                return flag;
            } else {
                flag = this.check_days();
                if (!flag) {
                    return flag;
                }
            }
            return flag;
        },
        async initMachine() {
            const url = `/api/machines/`;
            const res = await this.axios.get(url);
            this.machine_list = res.data.data;
            this.mac_name_data = this.machine_list.reduce((result, data) => {
                result[data['name']] = data;
                return result;
            }, {});
        },
        async handleCheckedColumnChangeSales(value) {
            if (value.length > this.last_checkeds.length) {
                const flag = await this.checkMchineCalendar(value.at(-1));
                if (flag) {
                    this.last_checkeds = value;
                } else {
                    this.checkeds.pop();
                }
            } else {
                this.last_checkeds = value;
            }
        },
        async checkMchineCalendar(mach_name) {
            const url = `/api/calendar/?mid=${this.mac_name_data[mach_name]['id']}`;
            const res = await this.axios.get(url);
            const calendar_list = res.data.data;
            let flag = true;
            for (let i = 0; i < calendar_list.length; i++) {
                if ((this.can_data.id || []).indexOf(calendar_list[i]['id']) === -1
                && calendar_list[i]['start'] === this.date_format(
                    JSON.parse(JSON.stringify(this.can_data)).start)
                && calendar_list[i]['repeat'].toString() === this.can_data.repeat) {
                    notify('error', `机台"${mach_name}"已存在相同日期开始，相同规则模式的日历规则，请先取消原来的日历规则！`, 0);
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        add_days() {
            const flag = this.check_days();
            if (flag) {
                this.days.push({
                    start_time: '',
                    end_time: '',
                });
                this.can_data.cycle = this.days.length;
            }
        },
        del_days(_, index) {
            this.days.splice(index, 1);
        },
        date_format(before_date) {
            const date = new Date(before_date);
            const diff_hour = date.getTimezoneOffset() / 60;
            const new_date = date.setHours(date.getHours() - diff_hour);
            return new Date(new_date).toISOString().slice(0, 10);
        },
        check_days() {
            let flag = true;
            for (let i = 0; i < this.days.length; i++) {
                if (!((!this.days[i]['start_time'] && !this.days[i]['end_time'])
                    || (this.days[i]['start_time'] && this.days[i]['end_time']))) {
                    notify('error', `第${i + 1}天的工作时间，需要都填写，或者都不填写！`, 0);
                    flag = false;
                    break;
                }
                if (this.days[i]['start_time'] > this.days[i]['end_time']) {
                    notify('error', `第${i + 1}天的起始时间不能大于截至时间！`, 0);
                    flag = false;
                    break;
                }
            }
            return flag;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.calendar-editor{
    position: relative !important;
    width: 90% !important;
    height: 100vh;
    max-height: 1200px;
    max-width: 1600px;
    min-height: 600px;
    min-width: 800px;
}
.calendar_form{
    position: relative;
    height: 100px;
}
.day_div {
    height: calc(100% - 330px) !important;
    position: absolute;
    top: 150px;
    bottom: 150px;
    width: 100%;
    left: 0px;
    padding: 2px 16px 2px 16px
}
.days {
    width: calc(100% - 56px) !important;
    height: 100% !important;
}
.day_table {
    height: 100% !important;
}
.mac_div {
    position: absolute !important;
    bottom: 55px;
    left:0px;
    width:100%;
    height: 100px;
    padding: 2px 16px 2px 16px
}
.machines{
    width: calc(100% - 56px) !important;
    height: 90px;
    overflow: auto;
    text-align: left;
}
.data_form .el-select__wrapper{
    width: 100% !important;
    min-height: 45px !important;
}
fieldset.info-box {
    border: 1px solid #dddddd;
    color: #aaaaaa;
}
fieldset.info-box.machines > .info-item {
    min-width: 100px;
    width: 8%;
}
fieldset.info-box > form {
    width: 100%;
}
legend{
    text-align: left;
}
</style>
