<!-- 展示计算过程中的日志信息 -->
<template>
    <el-descriptions
        direction="vertical"
        :title="title"
        :column="7"
        border>
        <el-descriptions-item v-for="(item,index) in formula" :key="index">
            <template #label>
                {{ item.label }}
            </template>
            <text v-show="item.formula!=''">{{ item.formula }} = </text>
            <text>{{ item.value }}{{ item.unit }}</text>
        </el-descriptions-item>
    </el-descriptions>
    <!-- <el-table
        v-if="formula.length>0"
        height="100%"
        :data="formula"
        :show-header="false">
        <el-table-column>
            <template #default="{row}">
                {{ row.label }} {{ row.formula }} = {{ row.value }}{{ row.unit }}
            </template>
        </el-table-column>
    </el-table> -->
</template>
<script>
export default {
    props: {
        inputData: {
            type: Object,
            required: true,
            default: null,
        },

    },
    emits: ['calculation-completed'],
    data() {
        return {
            result: {
                'pre': -1,
                'produced': -1,
            },
            parames: [],
            speeds: null,
            currentMacSpeed: null,
            formula: [],
        };
    },
    computed: {
        title() {
            if (this.result.pre > 0) {
                return `准备工时:${this.result.pre},生产工时:${this.result.produced}`;
            }
            return '公式信息';
        },
    },
    watch: {
    // 监视 inputData 变化，并重新计算
        inputData: {
            immediate: true, // 立即执行
            deep: true,
            handler(newVal, oldVal) {
                this.calculate(newVal, oldVal);
            },
        },
    },
    methods: {
        async getPara(cate) {
            const url = `/api/parameters/?c=${cate}`;
            const res = await this.axios.get(url);
            if (res.data.data.length > 0) {
                this.parames = res.data.data;
            } else {
                this.parames = [];
            }
        },
        async getMacSpeedList(machine, craft) {
            const urlspeed = `/api/speeds/?c=${craft.cate_name}`;
            const resSpeed = await this.axios.get(urlspeed);
            if (resSpeed.data.data.length > 0) {
                this.speeds = resSpeed.data.data[0];
            } else {
                this.speeds = null;
            }
        },
        async calculate(newVal, oldVal) {
            // 返回以前的计算结果
            if (!this.isCalcEnabled(newVal, oldVal)) {
                if (newVal.serialKey !== oldVal?.serialKey) {
                    this.result = {
                        'pre': -1,
                        'produced': -1,
                    };
                    this.formula.length = 0;
                }
                this.$emit('calculation-completed', this.result);
                return;
            }
            // 获取参数
            if (this.parames.length === 0 || newVal?.craft?.cate_name !== oldVal?.craft?.cate_name) {
                await this.getPara(newVal.craft.cate_name);
            }
            // 获取机台速度信息
            if (!this.speeds || newVal?.machine?.id !== oldVal?.machine?.id) {
                await this.getMacSpeedList(newVal.machine, newVal.craft);
            }
            // 重新设置机台速度
            this.calcMacSpeed();
            this.calcFormula();
            this.$emit('calculation-completed', this.result);
        },
        calcMacSpeed() {
            // 计算机台的速度
            const craft = this.inputData?.craft;
            if (craft?.cate_code === 'print') {
                const material = craft['材质'];
                const colorCount = craft['色序'].length;
                const speed = this.speeds?.speeds.find((item) => {
                    const [_s, _e] = item.props.色数;
                    return item.props.材质.includes(material)
                    && (colorCount >= _s && colorCount <= _e);
                });
                if (speed) {
                    this.currentMacSpeed = this.inputData.machine.speed * speed.value / 100;
                    return;
                }
                this.currentMacSpeed = this.inputData.machine.speed;
            }
        },
        /**
         * 从 colors 数组中排除包含 baseColors 中任意颜色的项目
         * 去除 \d+[.-]baseColor
         * @param {string[]} colors - 要过滤的颜色数组
         * @param {string[]} baseColors - 基础颜色数组
         * @returns {string[]} 过滤后的颜色数组
         */
        excludeBaseColors(colors, baseColors) {
            // 如果baseColors为空，则直接返回colors
            if (baseColors.length === 0 || baseColors.every(item => item === '')) return colors;
            // 构建正则表达式
            const regex = new RegExp(`\\d+[-.]?(${baseColors.join('|')})`);
            return colors.filter(color => !regex.test(color));
        },
        calcFormula() {
            this.formula.length = 0;
            if (this.inputData?.craft?.cate_code === 'print') {
                let colors = [];
                if (Array.isArray(this.inputData.craft['色序'])) {
                    colors = this.inputData.craft['色序'];
                } else if (typeof this.inputData.craft['色序'] === 'string') {
                    colors = this.inputData.craft['色序'].split(',');
                } else {
                    console.error('色序出现问题');
                }

                const colorCount = colors.length;
                const varnish = this.inputData.craft['光油'];
                // 设置时长
                const setup = this.parames.find((item) => {
                    return item.defined.name === '设置时长';
                });
                if (setup) {
                    this.formula.push(
                        {
                            label: setup.defined.name,
                            formula: '',
                            unit: '分钟',
                            value: setup.data,
                        },
                    );
                }
                // 换版
                const cRoll = this.parames.find((item) => {
                    return item.defined.name === '换版时长';
                });
                if (cRoll) {
                    this.formula.push(
                        {
                            label: cRoll.defined.name,
                            formula: `色数${colorCount}*换版${cRoll.data}${cRoll.defined.args.unit}`,
                            unit: '分钟',
                            value: cRoll.data * colorCount,
                        },
                    );
                }
                // 换色
                const cColor = this.parames.find((item) => {
                    return item.defined.name === '换色时长';
                });
                if (cColor) {
                    this.formula.push(
                        {
                            label: cColor.defined.name,
                            formula: `色数${colorCount}*换色${cColor.data}${cColor.defined.args.unit}`,
                            unit: '分钟',
                            value: cColor.data * colorCount,
                        },
                    );
                }
                // 洗胶辊时长
                const cGulue = this.parames.find((item) => {
                    return item.defined.name === '洗胶辊时长';
                });
                if (cGulue) {
                    this.formula.push(
                        {
                            label: cGulue.defined.name,
                            formula: `色数${colorCount}*洗胶辊${cGulue.data}${cGulue.defined.args.unit}`,
                            unit: '分钟',
                            value: cGulue.data * colorCount,
                        },
                    );
                }
                // 换光油
                if (varnish === '有') {
                    const cVarnish = this.parames.find((item) => {
                        return item.defined.name === '换光油时长';
                    });
                    if (cVarnish) {
                        this.formula.push(
                            {
                                label: cVarnish.defined.name,
                                formula: `${cVarnish.data} 分钟`,
                                unit: '分钟',
                                value: cVarnish.data,
                            },
                        );
                    }
                }
                // 调色时长
                const cMix = this.parames.find((item) => {
                    return item.defined.name === '调色时长';
                });
                if (cMix) {
                    const baseColor = this.parames.find((item) => {
                        return item.defined.name === '基础色';
                    });
                    const baseColors = baseColor?.data || [];
                    // 排除基础色,
                    // 因为基础色是数组['黑','白'],但是色序<colors>是['1.黑','2.白','3-蓝']
                    // 排除colors中的基础色,使用正则进行排除
                    console.log(baseColor, 'baseColor');
                    const noneBaseColor = this.excludeBaseColors(colors, baseColors);
                    this.formula.push(
                        {
                            label: cMix.defined.name,
                            formula: `非基础色数${noneBaseColor.length}*调色${cMix.data}${cMix.defined.args.unit}`,
                            unit: '分钟',
                            value: cMix.data * noneBaseColor.length,
                        },
                    );
                }
            }
            const produced = Math.ceil(this.inputData.quantity / this.currentMacSpeed);
            this.result.produced = produced;
            this.formula.push({
                label: '生产时长',
                formula: '',
                unit: '分钟',
                value: produced,
                type: 'prd',
            });
            this.result.pre = this.formula.reduce((pre, cur) => {
                if (cur?.type !== 'prd') {
                    return pre + cur.value;
                }
                return pre;
            }, 0);
        },
        isCalcEnabled(newVal) {
            // 判断是否需要重新计算公式
            if (newVal === null) {
                return false;
            }
            if (!newVal?.machine) {
                return false;
            }
            return true;
        },
    },

};
</script>
