<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="80%"
        height="80%"
        align-center
        class="machine-editor"
        :before-close="handleClose"
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="form_data"
            class="machine_form"
            :rules="form_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="订单号" class="label_style" prop="order_code">
                        <el-input
                            v-model="form_data.order_code"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="任务号" class="label_style" prop="job_code">
                        <el-input
                            v-model="form_data.job_code"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="产品" class="label_style" prop="product">
                        <el-input
                            v-model="form_data.product"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            disabled
                            maxlength="32">
                            <template #append>
                                <el-button @click="selectPrd">
                                    <el-icon><Operation /></el-icon>
                                </el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="数量" class="label_style" prop="quantity">
                        <el-input
                            v-model="form_data.quantity"
                            autocomplete="off"
                            placeholder=""
                            min="0"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="完成数量" class="label_style" prop="produced_quantity">
                        <el-input
                            v-model="form_data.produced_quantity"
                            autocomplete="off"
                            placeholder=""
                            min="0"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="最早开始日期" class="label_style" prop="earliest">
                        <el-date-picker
                            v-model="form_data.earliest" type="date" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            @change="triggerLastestCheck" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="最迟完成日期" class="label_style" prop="latest">
                        <el-date-picker
                            v-model="form_data.latest" type="date" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="是否完成" class="label_style" prop="is_finished">
                        <el-select
                            v-model="form_data.is_finished"
                            value-key="value"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option value="是">是</el-option>
                            <el-option value="否">否</el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注" class="label_style" prop="remark">
                        <el-input
                            v-model="form_data.remark"
                            type="textarea"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="handleClose">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
    <ProductListDlg ref="prdDlg" />
</template>

<script>
import {notify} from '../assets/js/utils.js';
import ProductListDlg from './product-list-dlg.vue';
import dayjs from 'dayjs';
import {Operation} from '@element-plus/icons-vue';

export default {
    name: 'JobListEdit',
    components: {
        ProductListDlg,
        Operation,
    },
    props: {
    },
    data() {
        return {
            form_data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '任务修改',
            form_rules: {
                order_code: [{required: true, message: '请输入订单号', trigger: ['change', 'blur']}],
                product: [{required: true, message: '请输入产品名称或者产品编号', trigger: ['change', 'blur']}],
                quantity: [{validator: this.quantityCheck, trigger: ['blur']}],
                produced_quantity: [{validator: this.quantityCheck, trigger: ['blur']}],
                earliest: [{required: true, message: '请输入最早开始日期', trigger: ['change']}],
                latest: [
                    {validator: this.latestCheck, trigger: ['change']},
                ],
                is_finished: [{required: true, message: '请选择是否完成', trigger: ['blur']}],
            },
        };
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        async show(options) {
            // await this.initCategory();
            this.visibled = true;
            this.title = options.title;
            this.form_data = options.data;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            try {
                if (!(await this.$refs.proform?.validate())) {
                    alert('请检查必填项！');
                    return;
                }
                if (!this.checked()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            const data = {
                ...this.form_data,
            };
            this.resolve(data);
            this.reject = null;
            this.visibled = false;
        },
        checked() {
            let flag = true;
            if (this.form_data.code) {
                for (let i = 0; i < this.machine_list.length; i++) {
                    if (this.form_data.id !== this.machine_list[i]['id']
                    && this.form_data.code.trim() === this.machine_list[i]['code'].trim()) {
                        notify('error', '机台编码重复！', 0);
                        flag = false;
                        break;
                    }
                    if (this.form_data.id !== this.machine_list[i]['id']
                    && this.form_data.name.trim() === this.machine_list[i]['name'].trim()) {
                        notify('error', '机台名称重复！', 0);
                        flag = false;
                        break;
                    }
                }
            }
            return flag;
        },
        async getProduct(product) {
            const url = `/api/products/?t=2&c=${product}`;
            const res = await this.axios.get(url);
            return res.data.data;
        },
        handleClose() {
            this.visibled = false;
            this.reject = null;
            this.resolve(null);
        },
        async selectPrd() {
            const res = await this.$refs.prdDlg.show();
            if (res && res?.code) {
                this.form_data.product = res?.name;
            }
        },
        quantityCheck(rule, value, callback) {
            if (isNaN(value)) {
                callback(new Error('数量必须为有效数字'));
                return;
            }
            if (value < 0) {
                callback(new Error('数量不能为负数'));
                return;
            }
            callback();
        },
        latestCheck(rule, value, callback) {
            if (!value) {
                callback(new Error('请输入最迟完成日期'));
                return;
            }
            if (!this.form_data.earliest) {
                // 如果最早开始日期为空，直接返回，不做校验
                callback();
                return;
            }
            if (dayjs(this.form_data.earliest).isAfter(dayjs(value), 'day')) {
                callback(new Error('最迟完成日期不能早于最早开始日期'));
                return;
            }
            callback();
        },
        triggerLastestCheck() {
            this.$nextTick(() => {
                this.$refs.proform.validateField('latest', () => null);
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.machine-editor{
    height: 100% !important;
    max-height: 700px;
    max-width: 1080px;
}
.machine_form {
    margin-bottom: 10px;
}
.machine_form label{
    font-size: 18px;
    padding: 7px 12px;
    text-align: justify;
    text-align-last: justify;
    background-color: #eee;
    font-weight: 400;
    height: 47px;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: block;
    min-width: 150px;
    max-width: 200px;
}
.machine_form input {
    font-size: 18px;
    border-radius: 6px;
    color: #555;
    height: 45px;
    background-color: #fff;
}
.process_table{
    margin-top: 10px;
    max-height: 400px;
}
.dagre{
    background-color:lightgray;
    border: 1px solid lightgray;
    margin-top: 20px;
    width: 100%;
}
.container{
    width: 300px;
    height: 100px;
}
.machine-editor footer{
    position: fixed;
    width: 100%;
    height: 50px;
    bottom: 0;
    margin-left: -8px;
    margin-right: -8px;
    background: #eaeaea;
    z-index: 999;
    padding: 0;
    text-align: center;
}
.btn_button{
    height: 50px !important;
    max-width: 300px !important;
    min-width: 200px !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    text-align: justify !important;
    text-align-last: justify !important;
}
.info-pannel {
    padding-left: 48px;
    padding-right: 8px;
}
.info-box {
    display: flex;
    flex-wrap: wrap;
}
.info-item {
    min-width: 300px;
    width: 25%;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.el-form-item {
    margin-bottom: 0 !important;
}
.mac_param_form {
    margin-top: 20px;
}
.foot_div{
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 50px;
    z-index: 999;
}
</style>
