<template>
    <el-dialog
        v-model="localDialogVisible"
        width="75%"
        top="2vh"
        append-to-body
        :before-close="handleClose">
        <el-descriptions
            direction="vertical"
            title="任务信息"
            :column="3"
            border>
            <el-descriptions-item>
                <template #label>
                    订单号
                </template>
                {{ lockJob.order_code }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    任务号
                </template>
                {{ lockJob.job_code }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    产品
                </template>
                {{ lockJob.product }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    数量
                </template>
                {{ lockJob.quantity }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    最早开始日期
                </template>
                {{ lockJob.earliest }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    最迟完成日期
                </template>
                {{ lockJob.latest }}
            </el-descriptions-item>
        </el-descriptions>
        <el-divider />
        <el-form
            ref="proform"
            :model="locked"
            class="machine_form"
            :rules="form_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="机台" class="label_style" prop="machine">
                        <el-input
                            v-model="locked.machine"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            disabled
                            maxlength="32">
                            <template #append>
                                <el-button @click="selectMac">
                                    <el-icon color="#409EFF"><MoreFilled /></el-icon>
                                </el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量" class="label_style" prop="quantity">
                        <el-input
                            v-model="locked.quantity"
                            autocomplete="off"
                            input-style="text-align: right;"
                            type="number"
                            min="0" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="锁定日期" class="label_style" prop="lockDate">
                        <el-date-picker
                            v-model="locked.lockDate" type="date" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD" :empty-values="[null]" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="准备" class="label_style" prop="pre">
                        <el-time-picker
                            v-model="locked.pre"
                            format="HH:mm" :empty-values="[null]" @change="triggerLockTimeCheck" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="开始" class="label_style" prop="run">
                        <el-time-picker
                            v-model="locked.run" format="HH:mm" :empty-values="[null]" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="完成" class="label_style" prop="end">
                        <el-time-picker
                            v-model="locked.end"
                            format="HH:mm" :empty-values="[null]" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <CalcInfoShow
                    :input-data="calcFormulaData" @calculation-completed="handleFormula" />
            </el-row>
            <el-row>
                <el-col :span="20" />
                <el-col :span="2" />
                <el-col :span="2">
                    <el-button type="primary" @click="addCustomLock">添加锁定</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-divider />
        <!-- 已排产信息 -->
        <h3>已排产信息</h3>
        <el-table
            v-if="schdList.length>0"
            border
            height="100%"
            :data="schdList"
            :row-class-name="lockedRowClass"
            header-cell-class-name="schd-header">
            <el-table-column
                align="center"
                width="40"
                label="" type="index" />
            <el-table-column
                prop="machine"
                width="120"
                label="机台" />
            <el-table-column
                prop="process_name"
                show-overflow-tooltip
                label="工序"
                width="120" />
            <el-table-column
                prop="pre"
                label="准备开始" />
            <el-table-column
                prop="run"
                label="开始时间" />
            <el-table-column
                prop="end"
                label="完成时间" />
            <el-table-column
                prop="date"
                label="日期" />
            <el-table-column fixed="right" label="" width="80" align="center">
                <template #default="scope">
                    <el-tooltip :content="scope.row.is_locked==='是'?'已锁定':'未锁定'" placement="left">
                        <el-button
                            link
                            :type="scope.row.is_locked==='是'?'warning':'primary'"
                            size="large"
                            @click="switchLock(scope)">
                            <el-icon v-if="scope.row.is_locked==='是'" :size="20"><Lock /></el-icon>
                            <el-icon v-else :size="20"><Unlock /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-divider />
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="closeDialog">关闭</el-button>
            </div>
        </template>
    </el-dialog>
    <MachineListDlg ref="macDlg" />
</template>

<script>
import dayjs from 'dayjs';
import MachineListDlg from './machine-list-dlg.vue';
import {MoreFilled, Lock, Unlock} from '@element-plus/icons-vue';
import SchdStore from '../stores/schdtabular.js';
import CalcInfoShow from './calc-info-show.vue';

// 加载已锁定信息
// 加载已有的排产信息
// 如果有已排产信息，则加载排产信息为table，在table上点击则会更改锁定信息
// @TODO 换版*色数,换色*色数 设置时长,调色(排除基础色) 洗胶辊*色数 光油(产品档案参数有就增加)
export default {
    components: {
        MachineListDlg,
        MoreFilled,
        Lock,
        Unlock,
        CalcInfoShow,
    },
    props: {
        dialogVisible: Boolean,
        jobInfo: {
            type: Object,
            default: () => {},
        },
        planDate: {
            type: String,
            default: () => {},
        },
    },
    emits: ['close'],
    setup() {
        const uWbStore = SchdStore();
        return {uWbStore};
    },
    data() {
        return {
            localDialogVisible: this.dialogVisible,
            multipleSelection: [],
            child_dlg_opened: false,
            locked: {
                pre: null,
                run: null,
                end: null,
                machine: '',
                machine_info: null,
                lockDate: null,
                quantity: 0,
            },
            form_rules: {
                run: [
                    {validator: this.lockRunTimeCheck, trigger: ['change']},
                ],
                end: [
                    {validator: this.lockEndTimeCheck, trigger: ['change']},
                ],
                quantity: [
                    {validator: this.lockQuantityCheck, trigger: ['change']},
                ],
                machine: [{validator: this.lockMachineCheck, trigger: ['change']}],
                pre: [{required: true, message: '请输入准备时间', trigger: ['change']}],
                lockDate: [{required: true, message: '请输入锁定日期', trigger: ['change']}],
            },
            calced_res: {},
        };
    },
    computed: {
        lockJob() { return {...this.jobInfo}; },
        craft() {
            const craft = (this.jobInfo?.product_info?.craft || []).find((item) => {
                return this.jobInfo.process_name === item.name;
            });
            return craft;
        },
        schdList() {
            return this.uWbStore.schdList.filter((schd) => {
                return schd.job_code === this.jobInfo.job_code;
            });
        },
        lockedJobList() {
            return this.uWbStore.jobLockList.filter((locked) => {
                return locked.job_code === this.jobInfo.job_code;
            });
        },
        calcFormulaData() {
            return {
                craft: this.craft,
                quantity: this.locked.quantity,
                machine: this.locked.machine_info,
                serialKey: `${this.lockJob.product_info.id}@${this.craft.name}`,
            };
        },
    },
    watch: {
        dialogVisible(newVal) {
            this.localDialogVisible = newVal;
        },
    },
    methods: {
        lockRunTimeCheck(rule, value, callback) {
            if (!value) {
                return callback(new Error('请选择开始时间'));
            }
            if (dayjs(this.locked.pre).isAfter(dayjs(value))) {
                return callback(new Error('开始时间不能早于准备时间'));
            }
            if (dayjs(this.locked.end).isBefore(dayjs(value))) {
                return callback(new Error('开始时间不能晚于完成时间'));
            }
            callback();
        },
        lockQuantityCheck(rule, value, callback) {
            if (!value) {
                return callback(new Error('请输入数量'));
            }
            if (value <= 0) {
                return callback(new Error('数量不能小于0'));
            }
            if (value > this.lockJob.quantity) {
                return callback(new Error(`数量(${value})不能超过任务数量(${this.lockJob.quantity})`));
            }
            callback();
        },
        lockEndTimeCheck(rule, value, callback) {
            if (!value) {
                return callback(new Error('请选择完成时间'));
            }
            if (dayjs(this.locked.run).isAfter(dayjs(value))) {
                return callback(new Error('完成时间不能早于开始时间'));
            }

            callback();
        },
        lockMachineCheck(rule, value, callback) {
            if (!value) {
                return callback(new Error('请选择机台'));
            }
            if (this.schdList.length > 0 && value !== this.schdList[0].machine) {
                return callback(new Error(`只能选择${this.schdList[0].machine}`));
            }

            callback();
        },
        triggerLockTimeCheck() {
            if (this.calced_res?.produce_time > 0) {
                this.locked.run = dayjs(this.locked.pre)
                    .add(this.calced_res.pre_time, 'minute').toDate();
                this.locked.end = dayjs(this.locked.pre)
                    .add(this.calced_res.pre_time + this.calced_res.produce_time, 'minute').toDate();
            }
            this.$nextTick(() => {
                this.$refs.proform.validateField('run', () => null);
            });
        },
        handleClose(done) {
            this.locked = {
                pre: null,
                run: null,
                end: null,
                machine: '',
                lockDate: null,
                quantity: 0,
            };
            this.$emit('close', done);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        closeDialog() {
            this.handleClose(() => {
                this.localDialogVisible = false;
            });
        },
        async addCustomLock() {
            try {
                if (!(await this.$refs.proform?.validate())) {
                    alert('请检查必填项！');
                    return;
                }
                const lockTpl = this.uWbStore.getEmpyRow('schdList');
                const toLockSchd = this.uWbStore.schdList.find(item =>
                    item.job_code === this.lockJob.job_code && item.date === this.locked.lockDate,
                );
                // lock

                // 处理时间
                lockTpl.job_code = this.lockJob.job_code;
                lockTpl.product = this.lockJob.product;
                lockTpl.quantity = this.locked.quantity;
                lockTpl.product_info = this.lockJob.product_info;
                lockTpl.process_name = this.lockJob.process_name;
                lockTpl.process_cate_name = this.lockJob.process_cate_name;
                lockTpl.machine = this.locked.machine;
                lockTpl.machine_info = this.locked.machine_info;
                lockTpl.date = this.locked.lockDate;
                lockTpl.pre = dayjs(this.locked.pre).format('HH:mm');
                lockTpl.run = dayjs(this.locked.run).format('HH:mm');
                lockTpl.end = dayjs(this.locked.end).format('HH:mm');
                lockTpl.is_locked = '是';
                if (toLockSchd) {
                    toLockSchd.quantity = lockTpl.quantity;
                    toLockSchd.pre = lockTpl.pre;
                    toLockSchd.run = lockTpl.run;
                    toLockSchd.end = lockTpl.end;
                    toLockSchd.is_locked = lockTpl.is_locked;
                } else {
                    this.uWbStore.addSchd([lockTpl]);
                }
            } catch (e) {
                console.warn(e);
            }
            // this.uWbStore.addJobs(this.jobInfo);
            // this.handleClose(() => {
            //     this.localDialogVisible = false;
            // });
        },
        disabledSeconds() {
            return Array.from({length: 59}, (_, index) => index + 1);
        },
        async selectMac() {
            this.child_dlg_opened = true;
            const res = await this.$refs.macDlg.show({'category': this.craft.cate_code});
            if (res && res?.code) {
                this.locked.machine = res?.name;
                this.locked.machine_info = res;
            }
            this.child_dlg_opened = false;
        },
        switchLock(scope) {
            const schd = this.uWbStore.getSchd(scope.row.serial_number);
            if (schd.is_locked !== '是') {
                schd.is_locked = '是';
            } else {
                schd.is_locked = '否';
            }
        },
        lockedRowClass({row}) {
            if (row.is_locked === '是') {
                return 'locked-row';
            } else {
                return '';
            }
        },
        handleFormula(result) {
            if (result?.pre > 0 || result?.produced > 0) {
                this.calced_res.pre_time = result.pre;
                this.calced_res.produce_time = result.produced;
            }
        },
    },
};
</script>
<style scoped>
:deep(.schd-header) {
    background-color: #f0f9eb  !important;
    color:#606266 !important;
}
:deep(.locked-header) {
    background-color: #fab6b6  !important;
    color:#606266 !important;
}
</style>
<style>
.el-table .locked-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
</style>
